import { graphql } from 'gatsby';
import React from 'react';
import CategoriesFilter from '../components/CategoriesFilter';
import ItemList from '../components/ItemList';
import SEO from '../components/SEO';

export default function ShopPage({ data, pageContext }) {
  const items = data.items.nodes;
  return (
    <>
      <SEO title={pageContext.category ? pageContext.category : `All Items`} />
      <CategoriesFilter activeCategory={pageContext.category} />
      <ItemList items={items} />
    </>
  );
}

export const query = graphql`
  query Items($category: [String]) {
    items: allSanityItem(
      filter: {
        public: { eq: true }
        categories: { elemMatch: { name: { in: $category } } }
      }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
        categories {
          id
          name
        }
        image {
          asset {
            fluid(maxWidth: 325, maxHeight: 325) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
